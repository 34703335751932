<template>
  <validation-observer ref="formAdmin">
    <form
      @submit.prevent="save"
    >
      <div class="animated fadeIn">
        <b-card class="px-md-2 py-md-1">
          <ResponseAlert ref="response" />
          <h3>{{ $route.meta.breadcrumb[1].text }}</h3>
          <b-row
            class="mt-2"
          >
            <b-col
              md="3"
              class="mb-2"
            >
              <div class="image-input">
                <validation-provider
                  #default="{ errors }"
                  name="Photo"
                  :rules="vars.rulesImage"
                >
                  <b-img
                    :src="getUrlOfFile(vars.photo)"
                    class="mb-2"
                  />
                  <label
                    v-if="!isDetailPage"
                    for="file-input"
                  >
                    <input
                      id="file-input"
                      type="file"
                      accept="image/png, image/gif, image/jpeg, image/jpg"
                      hidden
                      @change="AddPhoto"
                    >
                    <feather-icon icon="PlusIcon" />
                    Upload Photo
                  </label>
                  <input
                    v-model="vars.photo"
                    type="hidden"
                    :state="errors.length > 0 ? false:null"
                  >
                  <small class="text-danger text-center d-block m-auto">{{ errors[0] }}</small>
                </validation-provider>

              </div>
            </b-col>
            <b-col
              md="8"
            >
              <b-row
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="2">
                  <label
                    class="h5"
                    for="name"
                  >Nama</label>
                </b-col>
                <b-col sm="9">
                  <validation-provider
                    #default="{ errors }"
                    name="Nama"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="vars.fullName"
                      :disabled="isDetailPage"
                      type="text"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>

                  </validation-provider>
                </b-col>

              </b-row>
              <b-row
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="2">
                  <label
                    class="h5"
                    for="email"
                  >Email</label>
                </b-col>
                <b-col sm="9">
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="models.email"
                      :disabled="isDetailPage"
                      type="email"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="2">
                  <label
                    class="h5"
                    for="phone_number"
                  >Nomor HP</label>
                </b-col>
                <b-col sm="9">
                  <validation-provider
                    #default="{ errors }"
                    name="Nomor hp"
                    rules="required|numeric"
                  >
                    <b-form-input
                      id="phone_number"
                      v-model="models.phone"
                      :disabled="isDetailPage"
                      type="tel"
                      :state="errors.length > 0 ? false:null"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>

                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="2">
                  <label
                    class="h5"
                    for="role"
                  >Role</label>
                </b-col>
                <b-col sm="9">
                  <validation-provider
                    #default="{ errors }"
                    name="Role"
                    rules="required"
                  >
                    <b-form-select
                      id="role"
                      v-model="models.roleId"
                      :disabled="isDetailPage"
                      :options="masters.roles"
                      class="w-100"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>

                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                v-if="!isDetailPage"
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="2">
                  <label
                    class="h5"
                    for="password"
                  >Password</label>
                </b-col>
                <b-col sm="9">
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="password"
                  >
                    <b-input-group
                      class="input-group-merge"
                    >
                      <b-form-input
                        id="password"
                        v-model="vars.password"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="password"
                        :disabled="isAddPage"
                        placeholder="Masukkan password"
                        @keydown.space.prevent
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <!-- <b-form-input
                      value="p@ssword123"
                      disabled
                    /> -->
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="2">
                  <label
                    class="h5"
                    for="status"
                  >Status</label>
                </b-col>
                <b-col sm="10">
                  <b-form-input
                    v-if="isDetailPage"
                    :value="models.status ? 'Aktif' : 'Tidak Aktif'"
                    :disabled="isDetailPage"
                    type="text"
                  />
                  <div
                    v-else
                    class="col-md-7 col-lg-5 d-flex justify-content-between"
                  >
                    <label class="radio-inline">
                      <input
                        v-model="models.status"
                        type="radio"
                        :value="true"
                        name="status"
                      >
                      <span class="badge badge-success">Aktif</span>
                    </label>
                    <label class="radio-inline">
                      <input
                        v-model="models.status"
                        type="radio"
                        :value="false"
                        name="status"
                      >
                      <span class="badge badge-danger">Tidak Aktif</span>
                    </label>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-center justify-content-md-end mt-2 mt-md-0">

            <b-button
              type="button"
              variant="light"
              class="btn-min-width rounded"
              @click="$router.push({path: config.uri})"
            >
              {{ isDetailPage ? 'Kembali' : 'Batal' }}
            </b-button>
            <b-button
              v-if="!isDetailPage"
              type="submit"
              variant="primary"
              class="btn-min-width rounded ml-1"
            >
              Simpan
            </b-button>
          </div>
        </b-card>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import api from '@/utils/api'
import { uploadFile, getUrlOfFile } from '@/utils/helpers'

/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, numeric, image,
} from '@validations'
import {
  BCard, BRow, BCol, BFormSelect, BButton, BImg, BFormInput, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-cycle
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  name: 'FormAdmin',
  metaInfo: {
    title: 'Form Admin',
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormSelect,
    BButton,
    BImg,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      config: {
        uri: `/${this.$route.meta.link}`,
      },
      models: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        roleId: null,
        status: true,
        photo: '',
      },
      vars: {
        rulesImage: 'required|image',
        photo: null,
        fullName: '',
        password: '',
      },
      masters: {
        roles: [
          {
            text: '- Pilih Role -',
            value: null,
          },
        ],
      },
      // Functions
      getUrlOfFile,
      required,
      email,
      numeric,
      image,
    }
  },
  computed: {
    isAddPage() {
      return this.$route.name === 'add-admin'
    },
    isDetailPage() {
      return this.$route.name === 'detail-admin'
    },
    imageField() {
      return this.vars.photo
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    imageField() {
      if (typeof this.vars.photo !== 'string') {
        this.vars.rulesImage = 'required|image'
      }
    },
  },
  created() {
    this.getById()
    this.getRoles()
  },
  methods: {
    getById() {
      if (this.$route.params.id) {
        this.models.status = ''
        this.$axios.get(`${api.admin}/${this.$route.params.id}`)
          .then(res => {
            this.vars.rulesImage = 'required'

            const { data } = res.data
            this.models.firstName = data.profile.firstName
            this.models.lastName = data.profile.lastName
            this.models.email = data.email
            this.models.phone = data.profile.phone
            this.models.roleId = data.roleId
            this.models.status = data.status
            this.vars.photo = data.profile.photo
            this.vars.fullName = `${data.profile.firstName} ${data.profile.lastName}`
          }).catch(() => this.$router.push({
            name: 'page404',
          }))
      } else {
        this.vars.password = 'p@ssword123'
      }
    },
    AddPhoto(event) {
      const fileSizeLimit = 1024 * 1024 * 2 // limit 2 MB
      if (event.target.files[0].size < fileSizeLimit) {
        const photo = event.target.files[0]
        this.vars.photo = photo
        // eslint-disable-next-line no-param-reassign
        event.target.value = null
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed',
            text: 'Max file size is 2 MB',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
    },
    getRoles() {
      this.$axios.get(api.roles, {
        params: {
          sortBy: 'id.DESC',
        },
      }).then(res => {
        res.data.data.rows.forEach(role => {
          this.masters.roles.push({
            text: role.name,
            value: role.id,
          })
        })
      })
    },
    save() {
      this.$refs.formAdmin.validate().then(async success => {
        if (success) {
          let request = ''
          let message = 'create'

          // get file image url
          this.models.photo = typeof this.vars.photo === 'string' ? this.vars.photo : await uploadFile(this.vars.photo)
          // assign full name
          this.models.firstName = this.vars.fullName.split(' ')[0]
          this.models.lastName = this.vars.fullName.split(' ').splice(1).join(' ')

          if (!this.$route.params.id) {
            request = this.$axios.post(api.admin, this.models)
          } else {
            message = 'update'
            if (this.vars.password !== '') {
              this.models.password = this.vars.password
            }
            request = this.$axios.put(`${api.admin}/${this.$route.params.id}`, this.models)
          }
          request
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Success ${message} admin`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.$router.push(this.config.uri)
            })
            .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
        } else {
          window.scrollTo(0, 0)
        }
      })
    },
  },
}
</script>

<style>

</style>
